<template>
  <div>

    <CRow>
      <CCol lg="12">
      <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Data Report</h4> 
            <a target="_blank" href="#" class="float-right btn btn-danger form-control-sm"> EXCEL</a>           
            <a target="_blank" href="https://api-siperjadin.vercel.app/surattugass/pdf" class="float-right btn btn-danger form-control-sm" style='margin-right:10px'> PDF</a>            
            <!-- <a href="#/surattugas/add" class="float-right btn btn-success form-control-sm">Tambah Data Baru</a> -->
          </slot>
        </CCardHeader>
        <CCardBody>
      
        <table ref="table" class="table" id="datatable" :options="{ deleteSurattugas }">
          <thead>
            <tr>
              <th>No</th>
              <th style="width:80px">Nomor Surat Tugas</th>
              <th style="width:80px">Tanggal Surat Tugas</th>
              <th>Tanggal Pelaksanaan & Jumlah Hari</th>
              <th>Nama Pelaksana</th>
              <th style="width:80px">Dasar Surat Tugas</th>
              <th>Pejabat TTD Surat Tugas</th>
              <th style="width:100px">Status</th>
            </tr>
          </thead>
        </table>  

        </CCardBody>
      </CCard>  
  
      </CCol>
    </CRow>
    <CModal
      :show.sync="deleteModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Hapus Data"
      color="danger"
    >
      Anda Yakin Ingin Menghapus Data Ini?
      <template #header>
        <h6 class="modal-title">Hapus Data</h6>
        <CButtonClose @click="darkModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="deleteModal = false" color="light">Batal</CButton>
        <CButton @click="deleteSurattugas()" color="danger">Hapus</CButton>
      </template>
    </CModal>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 

let user = JSON.parse(localStorage.getItem('user'));
let level = user.level;
export default {
  
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    console.log(process.env.VUE_APP_BASE_URL);
    this.loadData();
  },
  data: function() {
        return {
          deleteModal : false,
            idselected:"0",
            products:[],
            items:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    confirmDelete: function(id){
      this.idselected = id;
      this.deleteModal = true;
    },
    deleteSurattugas: function(){
      // alert(this.idselected);/
      axios.delete(process.env.VUE_APP_BASE_URL+"surattugas/"+this.idselected)
        .then((response) => {
            
            if(response.status==200){
              this.deleteModal = false;
              $(this.$refs.table).DataTable().draw();
            }
            else{
              this.myError = true;
            }            
        })
    },

    loadData: function() {              
          
      if (this.tahun == null){
      
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
              "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"report/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no", class:"text-center"},
                  { "data": "no_surat_tugas", class:"text-center"},
                  { "data": "tgl_surat_tugas", class:"text-center"},
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            return `${row.tgl_pelaksanaan}<br>${row.jumlah_hari_pelaksanaan} `;
                        }, 
                  },
                  { "data": "nama_pelaksana", class:"text-center"},
                  { "data": "dasar_surat_tugas", class:"text-center"},
                  { "data": "pejabat_ttd", class:"text-center"},
                  { "data": "status_review", class:"text-center"},
                  // { "data": "edit", class:"text-center" },
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            alert(data.id_surat_tugas);
                            return "<button @click.prevent='confirmDelete(`"+row.id+"`)' data-id='$r[id_surat_tugas]' class='btn btn-sm btn-danger btnhapus btn2' >Hapus</button>";
                        }, 
                  },
                  
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        
       const table = $(this.$refs.table).DataTable({
          // $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
              "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"report/datatable",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "no", class:"text-center"},
                  { "data": "no_surat_tugas", class:"text-center"},
                  { "data": "tgl_surat_tugas", class:"text-center"},
                  { 
                    "data": null, 
                    class:"text-center",
                    "render": function(data, type, row, meta) {
                            return `${row.tgl_pelaksanaan}<br>${row.jumlah_hari_pelaksanaan} `;
                        }, 
                  },
                  { "data": "nama_pelaksana", class:"text-center"},
                  { "data": "dasar_surat_tugas", class:"text-center"},
                  { "data": "pejabat_ttd", class:"text-center"},
                  { "data": "status_review", class:"text-center"},
                  // { "data": level=='Sekretaris' ? null : "detail", class:"text-center" },
                  // { "data": "edit", class:"text-center" },
                  // { 
                  //   "data": null, 
                  //   class:"text-center",
                  //   "render": function(data, type, row, meta) {
                  //           // alert(data.id_penomoran);
                  //           return "<button @click.prevent='deleteSurattugas(`"+row.id+"`)' class='btn btn-sm btn-danger btnhapus btn2' >Hapus</button>";
                  //       }, 
                  // },
              ]  
          
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
          const self = this
          $('tbody', this.$refs.table).on( 'click', '.btnhapus', function(){
              const cell = table.cell( $(this).closest("td") );
              const objdata =cell.data();
              // alert(objdata.id);
              self.confirmDelete(objdata.id)
          });
        // })
      }
      
    }
  }
}
</script>